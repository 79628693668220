<template>
    <div>
        <div class="header">
            <a id="logo" href="/login/adm">
                <img src="../assets/img/logo.png" alt="logo" />
            </a>
            <p>Administração</p>
        </div>
        <div class="container">
            <form action="" @submit.prevent="salvaArquivo" class="envia-form">
                <div class="container-arquivo">
                    <div class="container-label">
                        <span class="label"> Selecione o arquivo de tarefas. </span>
                    </div>
                    <div class="container-input">
                        <input
                            type="file"
                            id="file"
                            ref="file"
                            name="file"
                            accept=".csv"
                            @change="handleFileUpload()"
                        />
                    </div>
                    <button type="submit" class="btn-arquivo">Enviar tarefas</button>
                </div>
            </form>
            <form action="" @submit.prevent="salvaBloqueio" class="envia-form">
                <div class="container-arquivo">
                    <div class="container-label">
                        <span class="label"> Selecione o arquivo de bloqueio/desbloqueio. </span>
                    </div>
                    <div class="container-input">
                        <input
                            type="file"
                            id="fileBloqueio"
                            ref="fileBloqueio"
                            name="bloqueio"
                            accept=".csv"
                            @change="handleBloqueioUpload()"
                        />
                    </div>
                    <button type="submit" class="btn-arquivo">Enviar bloqueios/desbloqueios</button>
                </div>
            </form>
        </div>
        <msg-sucess v-if="showSucess" :mensagemSucess="msgS"></msg-sucess>
        <msg-error v-if="showAlert" :mensagemError="msgE"></msg-error>

        <transition name="fade">
            <div v-if="isSending">
                <div class="popUp">
                    <div class="tela-mensagem">
                        <div class="waiting">
                            <div class="snippet" data-title=".dot-spin">
                                <div class="stage">
                                    <div class="dot-spin"></div>
                                </div>
                            </div>
                            <div class="sending">
                                Enviando
                                <div class="dot-pulse"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import axios from 'axios';
import MsgSucess from '../components/MsgSucess';
import MsgError from '../components/MsgError';

export default {
    data() {
        return {
            fileName: '',
            fileBloqueio: '',
            showSucess: false,
            showAlert: false,
            msgS: '',
            msgE: '',
            isSending: false,
        };
    },

    components: {
        MsgSucess,
        MsgError,
    },

    methods: {
        salvaArquivo() {
            let formData = new FormData();

            formData.append('tarefas', this.fileName);

            axios
                .post('https://api-valid.minus.eco.br/api/Condor/arquivos', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        authorization: 'Bearer !@minus_hash_!@',
                    },
                })
                .then(() => {
                    this.showSucess = true;
                    this.msgS = 'Arquivo enviado com sucesso! Tarefas serão geradas imediatamente.';
                })
                .catch(() => {
                    this.showAlert = true;
                    this.msgE = 'Não foi possível completar o envio do arquivo. Tente de novo ou fale com o suporte.';
                })
                .finally(() => {
                    setTimeout(() => {
                        location.reload();
                    }, 2500);
                });
        },

        salvaBloqueio() {
            let formData = new FormData();

            formData.append('bloqueio', this.fileBloqueio);

            this.isSending = true;

            axios
                .post('https://api-valid.minus.eco.br/api/Condor/arquivo-bloqueio', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        authorization: 'Bearer !@minus_hash_!@',
                    },
                })
                .then(() => {
                    this.showSucess = true;
                    this.msgS = 'Arquivo enviado com sucesso! Os bloqueios entrarão em vigor imediatamente.';
                })
                .catch(() => {
                    this.showAlert = true;
                    this.msgE = 'Não foi possível completar o envio do arquivo. Tente de novo ou fale com o suporte.';
                })
                .finally(() => {
                    this.isSending = false;
                    setTimeout(() => {
                        location.reload();
                    }, 2500);
                });
        },

        handleFileUpload() {
            this.fileName = this.$refs.file.files[0];
        },

        handleBloqueioUpload() {
            this.fileBloqueio = this.$refs.fileBloqueio.files[0];
        },
    },
};
</script>

<style scoped>
.popUp {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.tela-mensagem {
    position: absolute;
    left: 20%;
    top: 20%;
    bottom: 20%;
    right: 20%;
    margin: 18px;
    background-color: #fff;
    border-radius: 6px;
}

.waiting {
    padding: 20px;
    display: grid;
    justify-items: center;
    align-items: center;
    gap: 5%;
    height: -webkit-fill-available;
}

.sending {
    display: flex;
    gap: 20px;
    align-items: flex-end;
    font-size: 30px;
}

.container {
    display: grid;
    padding: 15px 20px;
    margin: 20px;
    box-shadow: 0 0 5px #cdcdcd;
}

.container-arquivo {
    border: 1px solid #cdcdcd;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px 10px;
}

header {
    width: 100%;
    max-width: 500px;
    display: flex;
    min-height: 56px;
    position: fixed;
    top: 0;
    background-color: #fff;
    z-index: 2;
}

.header {
    width: 100%;
    align-items: center;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--cinza);
}

.header p {
    font-size: 16px;
}

#logo {
    display: flex;
}

#logo img {
    width: 70px;
}

.header a img {
    width: 25px;
}

.label {
    font-size: 22px;
}

.container-label {
    margin-bottom: 20px;
}

.container-input input {
    font-size: 18px;
    cursor: pointer;
    background-color: #f4f4f4;
    border: 1px solid #cdcdcd;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
}

.container-input input:hover {
    background-color: #fff;
    transition: all 0.2s;
}

#file-upload-button {
    background-color: #fff;
}

.btn-arquivo {
    background-color: #f4f4f4;
    border: 1px solid #9b9b9b;
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s;
}

.btn-arquivo:hover {
    background-color: var(--verde);
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    transition: all 0.2s;
}

.dot-spin {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: transparent;
    color: transparent;
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff, 18px 0 0 0 #9880ff,
        12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0), 0 18px 0 0 rgba(152, 128, 255, 0),
        -12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0), -18px 0 0 0 rgba(152, 128, 255, 0),
        -12.72984px -12.72984px 0 0 rgba(152, 128, 255, 0);
    animation: dotSpin 1.5s infinite linear;
}

@keyframes dotSpin {
    0%,
    100% {
        box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff, 18px 0 0 0 #9880ff,
            12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0),
            -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
    }
    12.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 0 #9880ff, 18px 0 0 0 #9880ff,
            12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 -5px rgba(152, 128, 255, 0),
            -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
    }
    25% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 0 #9880ff,
            -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
    }
    37.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 0 #9880ff,
            -12.72984px 12.72984px 0 0 #9880ff, -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
    }
    50% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
            0 18px 0 0 #9880ff, -12.72984px 12.72984px 0 0 #9880ff, -18px 0 0 0 #9880ff,
            -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
    }
    62.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
            0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 #9880ff, -18px 0 0 0 #9880ff,
            -12.72984px -12.72984px 0 0 #9880ff;
    }
    75% {
        box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
            18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
            0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
            -18px 0 0 0 #9880ff, -12.72984px -12.72984px 0 0 #9880ff;
    }
    87.5% {
        box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff, 18px 0 0 -5px rgba(152, 128, 255, 0),
            12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0),
            -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0),
            -12.72984px -12.72984px 0 0 #9880ff;
    }
}

.dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    box-shadow: 9999px 0 0 -5px #9880ff;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
}

.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px #9880ff;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
}

.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px #9880ff;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
    0% {
        box-shadow: 9984px 0 0 -5px #9880ff;
    }
    30% {
        box-shadow: 9984px 0 0 2px #9880ff;
    }
    60%,
    100% {
        box-shadow: 9984px 0 0 -5px #9880ff;
    }
}

@keyframes dotPulse {
    0% {
        box-shadow: 9999px 0 0 -5px #9880ff;
    }
    30% {
        box-shadow: 9999px 0 0 2px #9880ff;
    }
    60%,
    100% {
        box-shadow: 9999px 0 0 -5px #9880ff;
    }
}

@keyframes dotPulseAfter {
    0% {
        box-shadow: 10014px 0 0 -5px #9880ff;
    }
    30% {
        box-shadow: 10014px 0 0 2px #9880ff;
    }
    60%,
    100% {
        box-shadow: 10014px 0 0 -5px #9880ff;
    }
}
</style>
